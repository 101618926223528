<template>
    <div class="container py-5">
        <h1>{{$t('signatureSigned.heading')}}</h1>
        <p>{{$t('signatureSaved.message')}}</p>
    </div>
</template>

<script>
export default {
    name: 'SignatureSaved',
    mounted () {
        window.sessionStorage.clear()
    }
}
</script>
